import React, { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Btn1 from "../../components/btn/Btn1";
import Line from "../../components/line/Line";

import "./CycleCalculator.css";

function CycleCalculator() {
  const [age, setAge] = useState("");
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [selectedSubstances, setSelectedSubstances] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [quantities, setQuantities] = useState(null);

  const handleSubmission = (event) => {
    event.preventDefault();
    // Verifică dacă valorile sunt în intervalul corect înainte de procesare
    const validAge = parseInt(age, 10) >= 18 && parseInt(age, 10) <= 60;
    const validWeight =
      parseInt(weight, 10) >= 40 && parseInt(weight, 10) <= 130;
    const validHeight =
      parseInt(height, 10) >= 140 && parseInt(height, 10) <= 200;

    if (validAge && validWeight && validHeight && selectedGoal !== null) {
      // Logica de procesare a datelor și generare a recomandărilor
      let newRecommendations = [];

      // Verifică alegerile pentru tipurile de substanțe
      if (selectedSubstances.includes("Injectabile")) {
        newRecommendations.push("Testo");
      }
      if (selectedSubstances.includes("Orale")) {
        newRecommendations.push(
          selectedGoal === "Masa Musculara" ? "Dianabol" : "Anavar"
        );
      }
      if (selectedSubstances.includes("Sarms")) {
        newRecommendations.push("MK677");
      }

      // Logica pentru combinarea tipurilor de substanțe
      if (
        selectedSubstances.includes("Injectabile") &&
        selectedSubstances.includes("Orale")
      ) {
        newRecommendations = ["Anavar", "Testo"];
      }

      // Setează recomandările în starea componentei
      setRecommendations(newRecommendations);

      // Calculează cantitățile în mg și setează-le în starea componentei
      setQuantities(getQuantityInMg());
    } else {
      alert("Te rugăm să completezi datele corect conform instrucțiunilor.");
    }
  };

  // Funcție pentru a obține cantitatea în mg în funcție de înălțime și greutate
  const getQuantityInMg = () => {
    const totalWeight = parseInt(weight, 10) + parseInt(height, 10);

    if (totalWeight >= 180 && totalWeight <= 220) {
      return {
        testo: "250mg/sapt",
        oral:
          selectedGoal === "Masa Musculara"
            ? "Dianabol 20mg/zi"
            : "Anavar 20mg/zi",
        SARMS: "MK677 25mg/zi",
      };
    } else if (totalWeight > 220 && totalWeight <= 250) {
      return {
        testo: "500mg/sapt",
        oral:
          selectedGoal === "Masa Musculara"
            ? "Dianabol 40mg/zi"
            : "Anavar 40mg/zi",
        SARMS: "MK677 30mg/zi",
      };
    } else if (totalWeight > 250 && totalWeight <= 330) {
      return {
        testo: "750mg/sapt",
        oral:
          selectedGoal === "Masa Musculara"
            ? "Dianabol 50mg/zi"
            : "Anavar 50mg/zi",
        SARMS: "MK677 40mg/zi",
      };
    } else {
      return {
        testo: "",
        oral: "",
        SARMS: "",
      };
    }
  };

  return (
    <section className="cycle-calculator">
      <Navbar></Navbar>
      <h1 className="text-2">Calculator Ciclu</h1>
      <p className="p-1">
        Obține recomandări pentru următorul tău ciclu adaptate în funcție de
        vârsta, greutatea și înălțimea ta. Alege obiectivul (definire,
        recompoziție, masă musculară) și tipurile de substanțe preferate.
        Realizează obiectivele tale de fitness cu ajutorul acestui calculator
        ușor de folosit!
      </p>
      <form>
        <label>
          Varsta:
          <input
            type="number"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            placeholder="18-60 ani"
            className="text"
            required
          />
        </label>
        <label>
          Greutate (kg):
          <input
            type="number"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            placeholder="40-130 kg"
            className="text"
            required
          />
        </label>
        <label>
          Inaltime (cm):
          <input
            type="number"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            placeholder="140-200 cm"
            className="text"
            required
          />
        </label>
        <Line></Line>
        <div className="choice">
          <p className="">Selecteaza tipurile de substante:</p>
          <label>
            <input
              type="checkbox"
              checked={selectedSubstances.includes("Injectabile")}
              onChange={() =>
                setSelectedSubstances((prev) =>
                  prev.includes("Injectabile")
                    ? prev.filter((s) => s !== "Injectabile")
                    : [...prev, "Injectabile"]
                )
              }
            />
            Injectabile
          </label>
          <label>
            <input
              type="checkbox"
              checked={selectedSubstances.includes("Orale")}
              onChange={() =>
                setSelectedSubstances((prev) =>
                  prev.includes("Orale")
                    ? prev.filter((s) => s !== "Orale")
                    : [...prev, "Orale"]
                )
              }
            />
            Orale
          </label>
          <label>
            <input
              type="checkbox"
              checked={selectedSubstances.includes("Sarms")}
              onChange={() =>
                setSelectedSubstances((prev) =>
                  prev.includes("Sarms")
                    ? prev.filter((s) => s !== "Sarms")
                    : [...prev, "Sarms"]
                )
              }
            />
            Sarms
          </label>
        </div>
        <Line></Line>

        <div className="choice">
          <p>Selecteaza obiectivul:</p>
          <label>
            <input
              type="radio"
              name="goal"
              checked={selectedGoal === "Definire"}
              onChange={() => setSelectedGoal("Definire")}
            />
            Definire
          </label>
          <label>
            <input
              type="radio"
              name="goal"
              checked={selectedGoal === "Recompoziție"}
              onChange={() => setSelectedGoal("Recompoziție")}
            />
            Recompoziție
          </label>
          <label>
            <input
              type="radio"
              name="goal"
              checked={selectedGoal === "Masa Musculara"}
              onChange={() => setSelectedGoal("Masa Musculara")}
            />
            Masa Musculara
          </label>
        </div>

        <div onClick={handleSubmission}>
          <Btn1>Generare Recomandare</Btn1>
        </div>

        {/* Afișează recomandările sub buton */}
        {recommendations.length > 0 && (
          <div className="recommendations">
            <p>Recomandări:</p>
            <ul>
              {recommendations.map((recommendation, index) => (
                <li key={index}>{recommendation}</li>
              ))}
            </ul>
          </div>
        )}

        {/* Afișează cantitățile în mg sub buton */}
        {quantities && (
          <div className="quantities">
            <p>Cantități în mg:</p>
            <ul>
              {Object.entries(quantities).map(
                ([substance, quantity], index) => (
                  <li key={index}>{`${substance}: ${quantity}`}</li>
                )
              )}
            </ul>
          </div>
        )}
      </form>
      <Footer></Footer>
    </section>
  );
}

export default CycleCalculator;
