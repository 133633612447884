import React, { useState } from "react";

import "./Advanced.css";
import { Link } from "react-router-dom";
import Card from "../../components/card/Card";
import Btn1 from "../../components/btn/Btn1";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import Modal from "../../components/modal/Modal";
import Line from "../../components/line/Line";
import SimpleSlider from "../../components/slider/SimpleSlider";

const Advanced = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <section className="advanced">
      <Navbar></Navbar>
      <Link to="/">
        <i className="fa-solid fa-house text-1"></i>
      </Link>
      <Card
        title="Ciclu avansat"
        url="https://cdn.muscleandstrength.com/sites/default/files/shirtless-muscle-man.holding-dumbbell-screaming.jpg"
      />
      <h2 className="text-2">Anadrol / Test / Tren</h2>
      <div className="details">
        <p>
          <span className="bold">Baza:</span> Testosteron / Trenbolon
        </p>
        <p>
          <span className="bold">AAS:</span> Anadrol
        </p>
        <p>
          <span className="bold">PCT:</span> Clomid / Nolvadex
        </p>
        <p>
          <span className="bold">AI:</span> Aromasin
        </p>
        <p>
          <span className="bold">Săptămâni:</span> 12
        </p>
        <p>
          <span className="bold">Rezultate:</span> MINIM +30kg
        </p>
      </div>
      <div onClick={openModal}>
        <Btn1>Comandă acum!</Btn1>
      </div>
      <p className="p-1">
        Fiecare persoană este diferită și necesită îndrumare personalizată
        pentru un ciclu de steroizi eficient. <br />{" "}
        <Link onClick={openModal}>Contactează-ne</Link> acum pentru o
        consultație gratuită.
      </p>

      <Line></Line>

      <SimpleSlider></SimpleSlider>

      <Line></Line>

      {isModalOpen && <Modal onClose={closeModal} />}
      <Footer></Footer>
    </section>
  );
};

export default Advanced;
