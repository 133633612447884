import React from "react";

import "./DesktopNotAvailable.css";

const DesktopNotAvailable = () => {
  return (
    <section className="desktop-not-avaible">
      {" "}
      <h2>Ne pare rău, dar site-ul pe desktop nu este disponibil.</h2>
      <p>Vă rugăm să utilizați un dispozitiv mobil pentru a accesa site-ul.</p>
    </section>
  );
};

export default DesktopNotAvailable;
