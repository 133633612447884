import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import "./AllProducts.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Banner3 from "../../components/banner/Banner3";
import Btn1 from "../../components/btn/Btn1";
import Modal from "../../components/modal/Modal";
import productsData from "../../ProductList";

const AllProducts = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [originalProducts, setOriginalProducts] = useState(productsData);
  const [filteredProducts, setFilteredProducts] = useState(originalProducts);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const productsPerPage = 10;

  useEffect(() => {
    updateDisplayedProducts();
  }, [filteredProducts]);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleCategoryChange = (selectedOption) => {
    const selectedCategory = selectedOption.value;
    filterProducts(selectedCategory);
  };

  const filterProducts = (category) => {
    if (category === "all") {
      setFilteredProducts(originalProducts);
    } else {
      const filtered = originalProducts.filter(
        (product) => product.category === category
      );
      setFilteredProducts(filtered);
    }
  };

  const updateDisplayedProducts = () => {
    const slicedProducts = filteredProducts.slice(0, productsPerPage);
    setDisplayedProducts(slicedProducts);
    setShowLoadMoreButton(filteredProducts.length > productsPerPage);
  };

  const loadMoreProducts = () => {
    const currentLength = displayedProducts.length;
    const nextSlice = filteredProducts.slice(
      currentLength,
      currentLength + productsPerPage
    );
    setDisplayedProducts([...displayedProducts, ...nextSlice]);
    setShowLoadMoreButton(
      filteredProducts.length > currentLength + productsPerPage
    );
  };

  const categoryOptions = [
    { value: "all", label: "Toate" },
    ...[...new Set(originalProducts.map((p) => p.category))].map(
      (category) => ({
        value: category,
        label: category,
      })
    ),
  ];

  return (
    <section className="all-products">
      <Navbar />
      <Link to="/">
        <i className="fa-solid fa-house text-1"></i>
      </Link>
      <Banner3 />
      <div className="filters">
        <label>
          Categorie:
          <Select
            options={categoryOptions}
            onChange={handleCategoryChange}
            defaultValue={categoryOptions[0]}
            isSearchable={false}
          />
        </label>
      </div>
      <div className="products">
        {displayedProducts.map((product) => (
          <div className="product" key={product.id}>
            <Link to={`/all-products/${product.name.replace(/ /g, "-")}`}>
              <LazyLoad height={200} offset={100}>
                <img src={product.img} alt={product.name} />
              </LazyLoad>
              <div className="product-content">
                <p className="product-category">{product.category}</p>
                <h2
                  className="text-3 product-name"
                  style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    WebkitLineClamp: 2,
                    whiteSpace: "normal",
                  }}
                >
                  {product.name}
                </h2>
                <p className="product-price"> {product.price} Ron</p>
                <div onClick={openModal}>
                  <Btn1>Comandă</Btn1>
                </div>
                {isModalOpen && <Modal onClose={closeModal} />}
              </div>
            </Link>
          </div>
        ))}
      </div>
      {showLoadMoreButton && (
        <div onClick={loadMoreProducts}>
          <Btn1>Încarcă mai multe produse</Btn1>
        </div>
      )}
      <Footer />
    </section>
  );
};

export default AllProducts;
