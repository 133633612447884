import React, { useEffect } from "react";
import "./Modal.css";
import Btn1 from "../btn/Btn1";
import { Link } from "react-router-dom";

const Modal = ({ onClose }) => {
  useEffect(() => {
    // Adaugă clasa când componenta este montată
    document.documentElement.classList.toggle("overflow");

    // Elimină clasa când componenta este demontată
    return () => {
      document.documentElement.classList.toggle("overflow");
    };
  }, []);

  return (
    <div className="modal">
      <div className="close-button" onClick={onClose}>
        <i className="fa-solid fa-xmark"></i>
      </div>
      <h2 className="text-2">Vrei să faci o schimbare?</h2>
      <div className="btns">
        <Link
          className="underlined"
          to={"whatsapp://send?phone=+40735110385"}
          target="_blank"
        >
          <Btn1>
            Contact de Whatsapp
            <i className="fa-brands fa-whatsapp social"></i>
          </Btn1>
        </Link>

        <Link
          className="underlined"
          to={"https://t.me/steroworld"}
          target="_blank"
        >
          <Btn1>
            Contact de Telegram
            <i className="fa-brands fa-telegram social"></i>
          </Btn1>
        </Link>
      </div>
      <div className="payments">
        <p className="p-1">Acceptăm:</p>
        <div className="payment">
          <p>Transfer bancar</p>
          <i className="fa-solid fa-building-columns"></i>
        </div>
        <div className="payment">
          <p>Crypto (BTC, USDT)</p>
          <i className="fa-brands fa-bitcoin"></i>
        </div>

        <div className="payment">
          <p>Western Union (numerar)</p>
          <i className="fa-solid fa-coins"></i>
        </div>
        {/* <i className="fa-solid fa-building-columns"></i>
        <i className="fa-brands fa-bitcoin"></i> */}
      </div>
    </div>
  );
};

export default Modal;
