// ProductList.js
const productsData = [
  // INJECTABILE
  {
    name: "Testosterone Cypionate 250mg",
    price: 195,
    descr:
      "Testosteronul Cypionate Driada Medical 250 mg este o variantă cu acțiune prelungită a testosteronului, similară cu testosteronul enanthate. Beneficiile includ stimularea dorinței sexuale, pierderea grăsimilor, creșterea masei musculare și densitatea osoasă, precum și susținerea sănătății inimii. Cu o administrare în intervalul de 200-600 mg pe săptămână, este apreciat pentru puterea sa ușor crescută față de testosteronul enanthate, devenind preferat în rândul atleților. Efectele pozitive sunt observate în diverse grupuri de vârstă și sexe.",
    category: "Injectabile",
    brand: "driada",
    id: 1,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/driada-medical-cypilos-testosterone-cypionate-10ml-vial-1000x1000h.jpg",
  },
  {
    name: "Testosterone Enanthate 250mg",
    price: 195,
    descr:
      "Testosteronul Enanthate Driada 250 mg este forma de testosteron frecvent recomandată pentru atleți și culturiști. Eficient în construirea forței și pierderea grăsimilor, este accesibil și ușor de găsit. Se administrează o doză între 250-750 mg pe săptămână, oferind o creștere rapidă a puterii și masei corporale. Pentru cei care caută masă musculară, adăugarea unui oral precum Anadrol sau Dianabol poate fi utilă.",
    category: "Injectabile",
    brand: "driada",
    id: 2,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/driada-medical-testos-testosteronee-enanthate-10ml-vial-1000x1000h.jpg",
  },
  {
    name: "Testosterone Propionate 100mg",
    price: 150,
    descr:
      "Testosteronul Propionat Driada Medical  100 mg este recunoscut pentru eficacitatea sa în dezvoltarea forței și menținerea masei musculare în perioadele de definire.  Acesta are rol esențial în dezvoltarea țesuturilor reproductive masculine și stimularea caracteristicilor sexuale secundare. Reacțiile anabolice includ creșterea masei musculare, forței și întărirea oaselor. Reacțiile androgene cuprind dezvoltarea organelor sexuale masculine și apariția caracteristicilor sexuale secundare. ",
    category: "Injectabile",
    brand: "Driada",
    id: 3,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/driada-medical-propios-testosterone-propionate-10ml-vial-1000x1000h-1.jpg",
  },
  {
    name: "Testosterone Undecanoate 250mg",
    price: 155,
    descr:
      "Testosteronul Undecanoat Driada Medical Andriolos 250, cunoscut și sub denumirea de Andriol și Aveed, este utilizat pentru a crește nivelurile de testosteron la bărbați. Cu un puternic efect androgenic și un efect anabolic moderat, este potrivit pentru terapia de substituție androgenică, administrat prin injecție musculară. Este considerat o formă naturală și bioidentică de testosteron.",
    category: "Injectabile",
    brand: "Driada",
    id: 4,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/driada-medical-andriolos-testosterone-undecanoate-10ml-vial-1000x1000h.jpg",
  },
  {
    name: "Trenbolone Acetate 100mg",
    price: 230,
    descr:
      "Trenbolone Acetate Driada Medical 100 mg este un steroid sintetic cu acțiune rapidă, asemănător cu testosteronul, susținând digestia proteinelor și menținând un echilibru azotat pozitiv. Stimulează pofta de mâncare și îmbunătățește sinteza proteinelor, fiind util pentru dezvoltarea forței și masei musculare în rândul culturiștilor și sportivilor. De asemenea, este administrat pentru menținerea masei musculare în condiții precum boli cronice sau traume severe. Trenbolone Acetate are proprietăți androgenice și anabolice, având un efect benefic în reducerea pierderii musculare și a durerilor osoase asociate osteoporozei.",
    category: "Injectabile",
    brand: "Driada",
    id: 5,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/driada-medical-trenacetos-trenbolone-acetate-10ml-vial-1000x1000h.jpg",
  },
  {
    name: "Trenbolone Enanthate 250mg",
    price: 230,
    descr:
      "Trenbolone Enanthate Driada Medical  200 mg este un androgen puternic cu proprietăți anabolice deosebit de eficiente. Acest steroid este popular în rândul culturiștilor care doresc să-și dezvolte masa musculară și să reducă acumularea de grăsimi. Generează rareori simptome de estrogen, precum retenție de apă și ginecomastie. În plus poate îmbunătăți masa corporală magră fără a necesita diete stricte. Mulți sportivi preferă acest medicament datorită faptului că nu este necesar să-l administreze frecvent.",
    category: "Injectabile",
    brand: "Driada",
    id: 6,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/driada-medical-trenentos-trenbolone-eanthate-10ml-vial-1000x1000h.jpg",
  },
  {
    name: "Trenbolone Hexahydrobenzyl 75mg",
    price: 280,
    descr:
      "Trenbolone Hexahydrobenzyl 75 mg este mai puternic decât testosteronul și acționează eficient, neconvertindu-se în estrogen în organism. Principala funcție a acestui medicament este stimularea apetitului și transformarea alimentelor în mușchi, nu în grăsime. Colaborează strâns cu receptori glucocorticoizi, inițiază sinteza proteinelor și blochează producția de androgeni endogeni. Nu provoacă retenție de apă sau ginecomastie, accelerând arderea grăsimilor. Doza zilnică recomandată este de 37-75 mg, crescută treptat, evitând supradozajul.",
    category: "Injectabile",
    brand: "Driada",
    id: 7,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/driada-medical-hexos-trenbolone-hexahydrobenzyl-carbonate-10ml-vial-1000x1000h.jpg",
  },
  {
    name: "Sustanon 250mg",
    price: 200,
    descr:
      "Sustanon Driada Medical 250 mg este o combinație de 4 eteri de testosteron: isocaproat, decanoat, propionat și fenilpropionat. Acești compuși complecși fac din Sustanon unul dintre cele mai durabile compuși steroizi create vreodată. Sustanon oferă sportivilor un impuls pentru antrenamente intense și adăugarea rapidă a forței și creșterea masei musculare. ",
    category: "Injectabile",
    brand: "Driada",
    id: 8,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/driada-medical-sustalad-sustanon-10ml-vial-1000x1000h.jpg",
  },
  {
    name: "Nandrolone 200mg",
    price: 200,
    descr:
      "Nandrolon Decanoat Driada Medical 200 este recunoscut ca unul dintre cei mai puternici steroizi injectabili pentru construirea masei musculare disponibili în prezent. Este foarte apreciat pentru capacitatea sa de a menține masa musculară și câștigurile de forță după ciclu, în același timp ameliorând problemele legate de durerea articulară și refacerea musculară.",
    category: "Injectabile",
    brand: "Driada",
    id: 9,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/driada-medical-nandecos-nandrolone-decanoate-10ml-vial-1000x1000h.jpg",
  },
  // {
  //   name: "Trenbolone Hexahydrobenzyl 75mg",
  //   price: 19,
  //   descr: "Lorem ipsum dolor sit amet consectetur, adipisicing elit.",
  //   category: "Injectabile",
  //   brand: "Driada",
  //   id: 10,
  //   img: "https://driada-medical.com/wp-content/uploads/2022/04/driada-medical-hexos-trenbolone-hexahydrobenzyl-carbonate-10ml-vial-1000x1000h.jpg",
  // },
  {
    name: "Drostanolone (Masteron) 100mg",
    price: 220,
    descr:
      "Drostanolon (Masteron) Driada Medical 100 mg este un steroid similar cu testosteronul. Acest medicament nu se transformă în estrogen, chiar dacă se exagerează cu doza, deoarece are proprietăți puternice anti-estrogenice. Aceasta înseamnă că, indiferent de doza administrată, nivelurile de estrogen nu vor fi depășite. Drostanolone promovează definirea musculară și menținerea masei musculare în timpul perioadelor de definire",
    category: "Injectabile",
    brand: "Driada",
    id: 11,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/driada-medical-mastelad-drostanolone-propionate-10ml-vial-1000x1000h.jpg",
  },
  {
    name: "Methenolone (Primobolan) 100mg",
    price: 280,
    descr:
      "Methenolone (Primobolan) Driada Medical 100 are efecte androgenice foarte reduse. Acest medicament are efecte limitate asupra creșterii forței și masei musculare. Atunci când sportivii administrează acest medicament, nu se produce o creștere rapidă în greutate și nici o creștere semnificativă a puterii. Aceasta este una dintre motivele pentru care Methenolone este utilizat pe o perioadă îndelungată, deoarece determină o creștere lentă, dar valoroasă a masei musculare, care este păstrată după întreruperea utilizării.",
    category: "Injectabile",
    brand: "Driada",
    id: 12,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/driada-medical-primos-methenolone-enanthate-10ml-vial-1000x1000h.jpg",
  },

  {
    name: "Dihydroboldos Cypionate ",
    price: 200,
    descr:
      "1-Testosteron (dihidroboldeon) este un steroid anabolic puternic, derivat din boldenon. Este structural similar cu Primobolan, dar lipsește grupul 1-metil pentru biodisponibilitate orală. Cu o putere anabolică de două ori mai mare decât testosteronul propionat și o androgenicitate moderată, oferă un favorabil raport anabolic-to-androgenic de 2:1. Apreciat de culturisti pentru promovarea creșterii semnificative a masei musculare fără retenție de apă, este non-estrogenic, minimizând efecte secundare precum ginecomastia și depunerea de grăsime. Ideal pentru fazele de tăiere, stimulează masa musculară de calitate fără volumul tipic asociat steroidelor cu aromatizare.",
    category: "Injectabile",
    brand: "Driada",
    id: 13,
    img: "https://driada-medical.com/wp-content/uploads/2023/04/Dihydroboldos.jpg",
  },
  {
    name: "Stakos 150mg (Cut stack) ",
    price: 190,
    descr:
      "Stakos Driada Medical 150 mg (Cut Stack) are o formă cu acțiune rapidă a hormonului. O formulă care oferă un impuls puternic cu mai puțină apă decât alte esteri ale medicamentului. Cut Stack conține trei compuși: testosteron, trenbolon, drostanolon, care acționează foarte puternic și sunt siguri să aducă rezultate de înaltă calitate și pronunțate.",
    category: "Injectabile",
    brand: "Driada",
    id: 14,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/driada-medical-stakos-cut-stack-10ml-vial-1000x1000h.jpg",
  },

  // ORALE
  {
    name: "Anavar 10mg (Oxandrolone)",
    price: 200,
    descr:
      "Anavar Driada Medical 10mg (Oxandrolon) este un steroid anabolic folosit pentru a crește forța și masa corporală. Medicamentul stimulează creșterea în greutate, iar la o doză moderată, efectele secundare nu apar. Anavar este folosit și pentru a trata efectele secundare ale altor steroizi și pentru a controla durerea în osteoartrită. Medicamentul stimulează organismul să producă testosteron, contribuind astfel la construirea masei musculare și creșterea forței. Durata maximă a ciclului este de 4 săptămâni.",
    category: "Orale",
    brand: "Driada",
    id: 15,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/anavaros_white-1000x1000h.jpg",
  },
  {
    name: "Clenbuterol 40mcg ",
    price: 100,
    descr:
      "Clenbuterol Driada Medical 40mcg promovează pierderea grăsimilor corporale în timp ce oferă creșteri de forță și masă musculară. Dacă vă interesează obținerea de forță, Clenbuterol pare să fie eficient pentru o perioadă mai scurtă, în jur de 3-4 săptămâni. Prezintă, de asemenea, o jumătate de viață extrem de lungă, aproximativ 34 de ore. Acest lucru face ca nivelurile constante de sânge să fie ușor de atins, necesitând doar o doză zilnică sau de două ori pe zi.",
    category: "Orale",
    brand: "Driada",
    id: 16,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/anavaros_white-1000x1000h.jpg",
  },
  {
    name: "Stanozol 10mg",
    price: 125,
    descr:
      "Stanozolol Driada Medical 10 mg nu reține apă în corp. Excelent pentru pregătirea pentru competiții. Permite obținerea unei musculaturi frumoase și pronunțate. Proprietățile medicamentului duc la dezvoltarea unor mușchi frumoși și de calitate, care nu sunt afectați după încheierea ciclului. Pentru a obține rezultate mai eficiente, ar trebui combinat cu alte medicamente precum Trenbolone Acetate sau Testosteron Propionate",
    category: "Orale",
    brand: "Driada",
    id: 17,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/stanos_white-1000x1000h.jpg",
  },
  {
    name: "Dianabol 10mg",
    price: 150,
    descr:
      "Dianabol Driada Medical 10 mg (Methandienone) este un steroid sintetic, foarte similar cu testosteronul, având abilități anabolice și androgenice. Medicamentul este foarte puternic în comparație cu altele, dar prezintă mai multe efecte negative. Este un steroid puternic estrogenic. Dianabol are un efect bun asupra sintezei proteinelor. De asemenea, are abilități anabolice și androgenice foarte bune. Crește acumularea de proteine și forță și creează un echilibru azotat.",
    category: "Orale",
    brand: "Driada",
    id: 18,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/dinabolyn_white-1000x1000h.jpg",
  },
  {
    name: "Nolvadex 10mg (Tamoxifen)",
    price: 95,
    descr:
      "Nolvadex Driada Medical 10mg (Tamoxifen) este  folosit pentru a gestiona efectele secundare legate de estrogen care pot apărea în urma administrării de steroizi anabolizanți, în special compuși care au potențialul de a se transforma în estrogen în organism. Aceste efecte secundare pot include ginecomastie, retenție de apă și acumulare de grăsimi. Prin blocarea receptorilor de estrogen, tamoxifenul poate contribui la prevenirea sau reducerea acestor efecte secundare.",
    category: "PCT",
    brand: "Driada",
    id: 19,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/nolvados_white-1000x1000h.jpg",
  },
  {
    name: "Armidex 0.5mg (Anastrozole)",
    price: 105,
    descr:
      "Armidex Driada Medical (Anastrozol) 0.5 mg nu este un steroid. Acest medicament are acțiune antiaromatizantă, fiind folosit de mulți sportivi pentru a preveni ginecomastia și alte efecte secundare nedorite asociate cu utilizarea steroizilor. Culturiștii utilizează aproximativ 0.5 mg până la 1 mg o dată pe zi și obțin rezultate excelente cu acest tratament.",
    category: "AI",
    brand: "Driada",
    id: 20,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/arimidyn_white-1000x1000h.jpg",
  },
  {
    name: "Clomid Citrate (Clomiphene) 25mg",
    price: 110,
    descr:
      "Clomid Driada Medical (Clomiphene) este  folosit în lumea culturismului în cadrul terapiei post-ciclu (PCT). Este un medicament antiestrogen, cunoscut și sub numele de Clomid, utilizat pentru a stimula producția naturală de testosteron după încheierea unui ciclu de steroizi anabolizanți. Sportivii și culturiștii recurg la Clomiphene Citrate pentru a contracara scăderea producției naturale de testosteron indusă de utilizarea steroizilor.",
    category: "PCT",
    brand: "Driada",
    id: 21,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/clomilad_white-1000x1000h.jpg",
  },
  {
    name: "Anadrol 50mg (Oxymetholone)",
    price: 150,
    descr:
      "Anadrol Driada Medical (Oxymetholone) 50mg este un puternic steroid anabolic utilizat pentru a promova creșterea rapidă a masei musculare și a forței. Acesta poate determina retenție de apă și poate avea efecte secundare semnificative. Este apreciat pentru capacitatea de a stimula sinteza de proteine și de a crește rapid forța. ",
    category: "Orale",
    brand: "Driada",
    id: 22,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/anavaros_white-1000x1000h.jpg",
  },
  {
    name: "Proviron 25mg",
    price: 150,
    descr:
      "Proviron Driada Medical 25mg (Mesterolone) este adesea folosit în contextul terapiei post-ciclu (PCT). În timpul unui ciclu de steroizi, organismul poate suprima producția naturală de testosteron. În faza de PCT, scopul este de a restabili producția naturală de testosteron. Provironus poate fi utilizat pentru a ajuta la menținerea nivelurilor de testosteron, acționând ca un androgen și având potențialul de a contracara simptomele asociate scăderii acestor niveluri. ",
    category: "PCT",
    brand: "Driada",
    id: 23,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/anavaros_white-1000x1000h.jpg",
  },
  {
    name: "Turinabol 10mg",
    price: 200,
    descr:
      "Turinabolin Driada Medical 10mg este un steroid anabolic utilizat în culturism, derivat al Dianabol-ului, cu efecte anabolice care contribuie la creșterea masei musculare și a forței. Se remarcă prin efectele mai blânde asupra retentiei de apa și aromatizarii în comparație cu Dianabol, oferind câștiguri mai curate în greutate.",
    category: "Orale",
    brand: "Driada",
    id: 24,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/turinadyn_white-1000x1000h.jpg",
  },

  //SARMS

  {
    name: "MK 677",
    price: 230,
    descr:
      "MK-677 Driada Medical (Ibutamoren), este un secretagog al hormonului de creștere utilizat în culturism și performanță fizică. Acest compus stimulează direct secreția de hormon de creștere uman (HGH), promovând astfel creșterea masei musculare și îmbunătățind performanța fizică. De asemenea, contribuie la accelerarea recuperării post-antrenament și poate stimula foamea, facilitând un aport caloric crescut în perioadele de bulking. MK-677 oferă aceste beneficii pozitive fără a interfera cu receptorii androgenici în mod selectiv, reprezentând o opțiune promițătoare pentru atingerea obiectivelor fără a implica riscuri asociate cu steroizii anabolizanți tradiționali.",
    category: "SARMS",
    brand: "Driada",
    id: 25,
    img: "https://driada-medical.com/wp-content/uploads/2022/08/MK-677-1000x1000-1.jpg",
  },
  {
    name: "RAD 140",
    price: 185,
    descr:
      "RAD-140, cunoscut și sub denumirea de Testolone, este un modulator selectiv al receptorilor androgenici (SARM) utilizat în culturism. Acest compus a fost dezvoltat pentru a imita efectele anabolice ale steroizilor, concentrându-și acțiunea asupra țesutului muscular și osos. RAD-140 sprijină în mod semnificativ creșterea musculară, contribuind la dezvoltarea unei mase musculare mai mari și mai puternice. Prin acțiunea sa specifică asupra receptorilor androgenici, RAD-140 minimizează efectele secundare negative asociate cu steroizii anabolizanți tradiționali. ",
    category: "SARMS",
    brand: "Driada",
    id: 26,
    img: "https://driada-medical.com/wp-content/uploads/2022/08/RAD140-1000x1000-1.jpg",
  },
  {
    name: "MK 2866",
    price: 150,
    descr:
      "MK-2866 Driada Medical (Ostarine), este un modulator selectiv al receptorilor androgenici (SARM) folosit în culturism. Acesta imită efectele anabolice ale steroizilor, concentrându-și acțiunea asupra țesutului muscular și osos. MK-2866 sprijină creșterea musculară, conservarea masei musculare în perioadele de cutting și promovează recuperarea. De asemenea, îmbunătățește rezistența minimizând efectele secundare negative asociate cu steroizii anabolizanți tradiționali. ",
    category: "SARMS",
    brand: "Driada",
    id: 27,
    img: "https://driada-medical.com/wp-content/uploads/2022/08/MK2866-1000x1000-1.jpg",
  },
  {
    name: "LGD4033",
    price: 175,
    descr:
      "LGD-4033 Driada Medical (Ligandrol), este un modulator selectiv al receptorilor androgenici (SARM) utilizat în culturism. Acesta sprijină creșterea masei musculare, îmbunătățește densitatea osoasă și favorizează pierderea grăsimilor, având potențial anabolic fără unele din efectele secundare ale steroizilor tradiționali. ",
    category: "SARMS",
    brand: "Driada",
    id: 28,
    img: "https://driada-medical.com/wp-content/uploads/2022/08/LGD-4033-1000x1000-1.jpg",
  },
  {
    name: "SR 9009",
    price: 200,
    descr:
      "SR-9009, cunoscut și sub denumirea de Stenabolic, este un compus sintetic care acționează ca agonist al Rev-ErbA, o proteină implicată în reglarea metabolismului și a ritmurilor circadiene. În culturism, SR-9009 este promovat pentru îmbunătățirea rezistenței, a metabolismului și a pierderii grăsimii corporale.",
    category: "SARMS",
    brand: "Driada",
    id: 29,
    img: "https://driada-medical.com/wp-content/uploads/2022/08/SR9009-1000x1000-2.jpg",
  },
  {
    name: "YK 11",
    price: 225,
    descr:
      "YK-11 Driada Medical este un modulator selectiv al receptorului androgenic (SARM) care se promovează în culturism pentru potențialul său de a sprijini creșterea masei musculare și a forței. Deși este considerat a avea acțiuni similare cu steroizii anabolizanți, YK-11 este menționat ca SARM, ceea ce înseamnă că ar trebui să acționeze selectiv în țesutul muscular fără a afecta în mod semnificativ alte organe.",
    category: "SARMS",
    brand: "Driada",
    id: 30,
    img: "https://driada-medical.com/wp-content/uploads/2022/08/YK11-1000x1000-1.jpg",
  },

  // HGH

  {
    name: "Somatropin Liquid 100iu",
    price: 900,
    descr:
      "Somatropin  Driada Medical HGH Liquid 100iu este un hormon de creștere uman vital pentru anabolism, creștere și regenerare celulară. Acest produs sprijină vindecarea rapidă după leziuni, repară țesutul muscular post-exercițiu, contribuie la creșterea masei musculare, accelerează metabolismul și arderea grăsimilor. Somatropin are beneficii și în cosmetologie, întârziind procesul de îmbătrânire și susținând regenerarea colagenului în piele. Utilizarea sa în tratamentul afecțiunilor legate de vârstă este însoțită de cercetări limitate. HGH stimulează metabolismul celular și producția de IGF-1, o substanță anabolică esențială pentru țesutul muscular.",
    category: "HGH",
    brand: "Driada",
    id: 31,
    img: "https://driada-medical.com/wp-content/uploads/2022/04/hgh3-1000x1000w.jpg",
  },
];

export default productsData;
