import React, { useState } from "react";

import "./Intermediate.css";
import Navbar from "../../components/navbar/Navbar";
import { Link } from "react-router-dom";
import Btn1 from "../../components/btn/Btn1";
import Footer from "../../components/footer/Footer";
import Card from "../../components/card/Card";
import Modal from "../../components/modal/Modal";
import SimpleSlider from "../../components/slider/SimpleSlider";
import Line from "../../components/line/Line";

const Intermediate = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <section className="intermediate">
      <Navbar></Navbar>
      <Link to="/">
        <i className="fa-solid fa-house text-1"></i>
      </Link>
      <Card
        title="Ciclu intermediar"
        url="https://brutalforce.com/wp-content/uploads/2021/02/how-to-bulk-up-fast-1.jpg"
      />
      <h2 className="text-2">Test / Dbol / Deca</h2>
      <div className="details">
        <p>
          <span className="bold">Baza:</span> Testosteron Enanthate
        </p>
        <p>
          <span className="bold">AAS:</span> Dianabol / Deca-durabolin
        </p>
        <p>
          <span className="bold">PCT:</span> Clomid / Nolvadex
        </p>
        <p>
          <span className="bold">AI:</span> Aromasin
        </p>
        <p>
          <span className="bold">Săptămâni:</span> 12
        </p>
        <p>
          <span className="bold">Rezultate:</span> MINIM +20kg
        </p>
      </div>
      <div onClick={openModal}>
        <Btn1>Comandă acum!</Btn1>
      </div>
      <p className="p-1">
        Fiecare persoană este diferită și necesită îndrumare personalizată
        pentru un ciclu de steroizi eficient. <br />
        <Link onClick={openModal}>Contactează-ne</Link> acum pentru o
        consultație gratuită.
      </p>

      <Line></Line>

      <SimpleSlider></SimpleSlider>

      <Line></Line>

      {isModalOpen && <Modal onClose={closeModal} />}

      <Footer></Footer>
    </section>
  );
};

export default Intermediate;
