import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import LazyLoad from "react-lazyload";
import productsData from "../../ProductList";

import "./ProductDetail.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Btn1 from "../../components/btn/Btn1";
import Modal from "../../components/modal/Modal";
import Line from "../../components/line/Line";
import SimpleSlider from "../../components/slider/SimpleSlider";

const ProductDetail = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const { productName } = useParams();

  const decodedProductName = productName.replace(/-/g, " ");

  const product = productsData.find((p) => p.name === decodedProductName);

  if (!product) {
    return <div>Produsul nu a fost gasit</div>;
  }

  return (
    <section className="product-detail">
      <Navbar />
      <Link to={"/all-products"}>
        <i className="fa-solid fa-left-long text-1"></i>
      </Link>
      <div>
        <p className="product-category">{product.category}</p>
        <h2>{product.name}</h2>
        <LazyLoad height={200} offset={100}>
          <img src={product.img} alt={product.name} />
        </LazyLoad>
        <p className="product-price">Preț: {product.price} Ron</p>
        <p className="product-descr">{product.descr}</p>
        <div onClick={openModal}>
          <Btn1>Comandă acum!</Btn1>
        </div>

        <Line></Line>

        <SimpleSlider></SimpleSlider>

        <Line></Line>

        {isModalOpen && <Modal onClose={closeModal} />}
      </div>
      <Footer />
    </section>
  );
};

export default ProductDetail;
