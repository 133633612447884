import React, { useState } from "react";
import "./Landing.css";
import Navbar from "../../components/navbar/Navbar";
import Banner1 from "../../components/banner/Banner1";
import Line from "../../components/line/Line";
import Btn1 from "../../components/btn/Btn1";
import Card from "../../components/card/Card";
import Banner2 from "../../components/banner/Banner2";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import SimpleSlider from "../../components/slider/SimpleSlider";

const Landing = () => {
  return (
    <main className="landing">
      <section className="home">
        <Navbar></Navbar>
        <Banner1></Banner1>
        <h1 className="text-1">Stero World</h1>
        <p className="p-1">
          La SteroWorld, veți descoperi o selecție de steroizi premium, atât
          orali cât și injectabili, fiecare însoțiți de un cod QR pentru
          verificarea autenticității.
        </p>
        <Line></Line>
        <Link to={"/all-products"}>
          <Btn1>Cumpără acum!</Btn1>
        </Link>
      </section>

      <section className="categories" id="categories">
        <h2 className="text-2">Care este punctul tău de pornire?</h2>
        <div className="row">
          <Link to="/beginner">
            <Card
              title="Începător"
              url="https://res.cloudinary.com/dsqwnuyiw/image/upload/e_auto_contrast,f_webp/v1706010750/incepator_k8xeay.webp"
            />
          </Link>
          <Link to="/intermediate">
            <Card
              title="Intermediar"
              url="https://res.cloudinary.com/dsqwnuyiw/image/upload/e_auto_contrast,f_webp/v1706010750/Intermediar_owkcjx.webp"
            />
          </Link>
          <Link to="advanced">
            <Card
              title="Avansat"
              url="https://res.cloudinary.com/dsqwnuyiw/image/upload/e_auto_contrast,f_webp/v1706010751/avansat_fstgg4.webp"
            />
          </Link>
        </div>
      </section>

      <section>
        <Line></Line>
        <SimpleSlider></SimpleSlider>
      </section>

      <section className="actions">
        <Line></Line>
        <h2 className="text-2">Ești pregătit să faci o schimbare?</h2>
        <Banner2></Banner2>
        <p className="p-1">
          * Toate produsele conțin un cod QR care atestă autenticitatea *
        </p>

        <Link to={"/all-products"}>
          <Btn1>Cumpără acum!</Btn1>
        </Link>
      </section>

      <section className="features">
        <div className="feature">
          <p className="text-2">Livrare în 24/48h</p>
          <i className="icon fa-solid fa-truck-fast"></i>
        </div>
        <div className="feature">
          <p className="text-2">Livrare gratuită</p>
          <i className="icon fa-solid fa-cart-shopping"></i>
        </div>
        <div className="feature">
          <p className="text-2">Produse testate</p>
          <i className="icon fa-solid fa-check"></i>
        </div>
        <div className="feature">
          <p className="text-2">+137 de clienți</p>
          <i className="icon fa-solid fa-face-smile"></i>
        </div>
        <Link to={"/all-products"}>
          <Btn1>Cumpără acum!</Btn1>
        </Link>
      </section>
      <section className="sponsors">
        <div className="row">
          <img
            className="sponsor-img"
            src={
              "https://res.cloudinary.com/dsqwnuyiw/image/upload/f_webp/v1705758195/sponsor1_znea7b.webp"
            }
            alt="Driada Medical"
          />
          <img
            className="sponsor-img"
            src={
              "https://res.cloudinary.com/dsqwnuyiw/image/upload/f_webp/v1705758195/sponsor2_iuqtyc.webp"
            }
            alt="Pharma Gen"
          />
          <img
            className="sponsor-img"
            src={
              "https://res.cloudinary.com/dsqwnuyiw/image/upload/f_webp/v1705758195/sponsor3_sxajf2.webp"
            }
            alt="Pfizer"
          />
          <img
            className="sponsor-img"
            src={
              "https://res.cloudinary.com/dsqwnuyiw/image/upload/f_webp/v1705758195/sponsor4_xxlc5b.webp"
            }
            alt="Rc"
          />
        </div>
      </section>
      <Footer></Footer>
    </main>
  );
};

export default Landing;
