import React from "react";
import LazyLoad from "react-lazyload";
import "./Banner.css";

const Banner3 = () => {
  return (
    <div className="banner banner3">
      <LazyLoad height={200} offset={100}>
        <img
          className="banner-img"
          src={
            "https://res.cloudinary.com/dsqwnuyiw/image/upload/e_auto_contrast,f_webp/v1706010534/banner3_lpi4cz.webp"
          }
          alt="banner Toate Produsele"
        />
      </LazyLoad>
      <h2 className="text-2">Toate produsele</h2>
    </div>
  );
};

export default Banner3;
