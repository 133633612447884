import React from "react";
import LazyLoad from "react-lazyload";
import "./Card.css";

const Card = (props) => {
  const { title, url } = props;

  return (
    <LazyLoad height={200} offset={100}>
      <div className="card" style={{ backgroundImage: `url(${url})` }}>
        <h3 className="card-title text-2">{title}</h3>
      </div>
    </LazyLoad>
  );
};

export default Card;
