// Btn1.js
import React from "react";
import "./Btn.css";

const Btn1 = ({ children, scrollTo }) => {
  const handleClick = () => {
    const targetElement = document.querySelector(scrollTo);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth", // Aggiunge l'effetto di "ease"
      });
    }
  };

  return (
    <button className="btn btn1" onClick={handleClick}>
      {children}
    </button>
  );
};

export default Btn1;
