import React from "react";
import LazyLoad from "react-lazyload";
import "./Banner.css";

const Banner1 = () => {
  return (
    <div className="banner banner1">
      <LazyLoad height={200} offset={100}>
        <img
          className="banner-img"
          src={
            "https://res.cloudinary.com/dsqwnuyiw/image/upload/f_webp/v1705758012/home_banner_rcmfzx.webp"
          }
          alt="home banner"
        />
      </LazyLoad>
    </div>
  );
};

export default Banner1;
