import React from "react";
import "./Review.css";

const Review = ({ title, description, rating, halfRating, name, image }) => {
  return (
    <div className="review">
      <h4 className="review-title">"{title}"</h4>
      <p className="review-descr">{description}</p>
      <img className="review-img" src={image} alt="review img" />
      <h4 className="review-name">{name}</h4>
      <div className="stars">
        {[...Array(rating)].map((_, i) => (
          <i key={i} className="fa-solid fa-star"></i>
        ))}
        {[...Array(halfRating)].map((_, i) => (
          <i key={i} className="fa-solid fa-star-half-stroke"></i>
        ))}
      </div>
    </div>
  );
};

export default Review;
