import React from "react";
import LazyLoad from "react-lazyload";
import "./Banner.css";

const Banner2 = () => {
  return (
    <div className="banner banner2">
      <LazyLoad height={200} offset={100}>
        <img
          className="banner-img"
          src={
            "https://res.cloudinary.com/dsqwnuyiw/image/upload/f_webp/v1705758345/actions_banner_r0a680.webp"
          }
          alt="take actions now"
        />
      </LazyLoad>
      <h2 className="text-2">Take actions now</h2>
    </div>
  );
};

export default Banner2;
