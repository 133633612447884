import React, { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Card from "../../components/card/Card";

import "./Begginer.css";
import Btn1 from "../../components/btn/Btn1";
import { Link } from "react-router-dom";
import Modal from "../../components/modal/Modal";
import SimpleSlider from "../../components/slider/SimpleSlider";
import Line from "../../components/line/Line";

const Begginer = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <section className="begginer">
      <Navbar></Navbar>
      <Link to="/">
        <i className="fa-solid fa-house text-1"></i>
      </Link>
      <Card
        title="Ciclu începători"
        url="https://imgnew.outlookindia.com/uploadimage/library/16_9/16_9_5/IMAGE_1681130242.jpg"
      />
      <h2 className="text-2">Doar Testosteron</h2>
      <div className="details">
        <p>
          <span className="bold">Baza:</span> Testosteron Enanthate
        </p>
        <p>
          <span className="bold">AAS:</span> /
        </p>
        <p>
          <span className="bold">PCT:</span> Clomid
        </p>
        <p>
          <span className="bold">AI:</span> /
        </p>
        <p>
          <span className="bold">Săptămâni:</span> 12
        </p>
        <p>
          <span className="bold">Rezultate:</span> MINIM +10kg
        </p>
      </div>
      <div onClick={openModal}>
        <Btn1>Comandă acum!</Btn1>
      </div>
      <p className="p-1">
        Fiecare persoană este diferită și necesită îndrumare personalizată
        pentru un ciclu de steroizi. <br />{" "}
        <Link onClick={openModal}>Contactează-ne</Link> acum pentru o
        consultație gratuită.
      </p>

      <Line></Line>

      <SimpleSlider></SimpleSlider>

      <Line></Line>

      {isModalOpen && <Modal onClose={closeModal} />}
      <Footer></Footer>
    </section>
  );
};

export default Begginer;
