import React from "react";

import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const toggleMenu = () => {
    document.body.classList.toggle("toggleMenu");
    document.documentElement.classList.toggle("overflow");
  };

  return (
    <nav>
      <Link to="/">
        <h2 className="logo text-2">Steroworld</h2>
      </Link>
      <div onClick={toggleMenu} className="menu-btn">
        <span className="span1"></span>
        <span className="span2"></span>
        <span className="span3"></span>
      </div>

      <div className="links">
        <ul>
          <li className="bold">Link</li>

          <Link onClick={toggleMenu}>
            <li className="link">Acasă</li>
          </Link>

          <Link to={"/all-products"} onClick={toggleMenu}>
            <li className="link">Toate produsele</li>
          </Link>

          <Link to={"/beginner"} onClick={toggleMenu}>
            <li className="link">Ciclu începători</li>
          </Link>

          <Link to={"/intermediate"} onClick={toggleMenu}>
            <li className="link">Ciclu intermediari</li>
          </Link>

          <Link to={"/advanced"} onClick={toggleMenu}>
            <li className="link">Ciclu avansați</li>
          </Link>
          <li className="bold">Contact</li>
          <Link
            className="underlined"
            to={"whatsapp://send?phone=+40735110385"}
          >
            Whatsapp: 0735110385
          </Link>
          <Link className="underlined" to={"https://t.me/steroworld"}>
            Telegram: @SteroWorld
          </Link>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
