import React from "react";

import "./Footer.css";
import Line from "../line/Line";
import Card from "../card/Card";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <Link to={"/"}>
        <h3 className="logo text-2">Steroworld</h3>
      </Link>
      <Line></Line>
      <div className="links">
        <ul>
          <li className="bold">Links</li>

          <Link to={"/"}>
            <li className="link">Acasă</li>
          </Link>

          <Link to={"/all-products"}>
            <li className="link">Toate produsele</li>
          </Link>

          <Link to={"/beginner"}>
            <li className="link">Ciclu începători</li>
          </Link>

          <Link to={"/intermediate"}>
            <li className="link">Ciclu intermediari</li>
          </Link>

          <Link to={"/advanced"}>
            <li className="link">Ciclu avansați</li>
          </Link>
          <li className="bold">Contact</li>
          <Link
            className="underlined"
            to={"whatsapp://send?phone=+40735110385"}
          >
            Whatsapp: 0735110385
          </Link>
          <Link className="underlined" to={"https://t.me/steroworld"}>
            Telegram: @SteroWorld
          </Link>
        </ul>
      </div>
      <p className="copyright">
        SteroWorld © 2024. <br /> All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
