import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Landing from "./pages/landing/Landing";
import Begginer from "./pages/begginer/Begginer";
import Intermediate from "./pages/intermediate/Intermediate";
import Advanced from "./pages/advanced/Advanced";
import ScrollToTop from "./components/ScrollToTop";

import "./App.css";
import AllProducts from "./pages/allProducts/AllProducts";
import ProductDetail from "./pages/productDetail/ProductDetail";
import DesktopNotAvailable from "./pages/desktopNotAvailable/DesktopNotAvailable";
import CycleCalculator from "./pages/cycleCalculator/CycleCalculator";

function App() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 630);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 630);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="App">
      <ScrollToTop />

      {!isDesktop ? (
        <Routes>
          <Route element={<Landing />} path="/" />
          <Route element={<Begginer />} path="/beginner" />
          <Route element={<Intermediate />} path="/intermediate" />
          <Route element={<Advanced />} path="/advanced" />
          <Route element={<AllProducts />} path="/all-products" />
          <Route
            element={<ProductDetail />}
            path="/all-products/:productName"
          />
          <Route element={<CycleCalculator />} path="/cycle-calculator" />
        </Routes>
      ) : (
        <DesktopNotAvailable />
      )}
    </div>
  );
}

export default App;
