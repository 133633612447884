import React from "react";
import Slider from "react-slick";
import Review from "../review/Review";

import "./SimpleSlider.css";

export default function SimpleSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
  };

  const reviews = [
    {
      title: "Cele mai bune produse din Romania",
      description:
        "Am observat rezultate vizibile în doar câteva săptămâni. Merită fiecare bănuț!",
      rating: 5,
      halfRating: 0,
      name: "Andrei Popescu",
      image:
        "https://driadashop.eu/image/cache/catalog/review1/59-1920x1080.jpeg.webp",
    },
    {
      title: "Comandă pentru mine și soțul meu",
      description:
        "Produs excelent, a făcut o diferență notabilă și la mine și la soțul meu. ",
      rating: 4,
      halfRating: 1,
      name: "Maria Ionescu",
      image:
        "https://driadashop.eu/image/cache/catalog/review1/58-1000x1619.jpeg.webp",
    },
    {
      title: "Testosteron Enanthate și Anavar",
      description:
        "Serviciul pentru clienți este excelent, iar produsele sunt de top. Foarte mulțumit!",
      rating: 5,
      halfRating: 0,
      name: "Alexandru Vasilescu",
      image:
        "https://driadashop.eu/image/cache/catalog/review1/56-1000x599.jpeg.webp",
    },
    {
      title: "Folosesc produsele Driada de 2 ani",
      description:
        "Rezultatele sunt uimitoare! Nu mă așteptam la o astfel de îmbunătățire.",
      rating: 4,
      halfRating: 1,
      name: "Sorin Iacob",
      image:
        "https://driadashop.eu/image/cache/catalog/review1/53-1000x750.JPG.webp",
    },
    {
      title: "Livrare în 2 zile lucratoare",
      description:
        "Livrare rapidă și produse de calitate. Voi reveni cu o comandă mult mai mare curând.",
      rating: 4,
      halfRating: 0,
      name: "Stefan Petrescu",
      image:
        "https://driadashop.eu/image/cache/catalog/review1/54-828x824.jpg.webp",
    },
    {
      title: "12 cutii de Clenbuterol. Totul în regulă",
      description:
        "Totul a mers cum trebuie, de la plată, la comandă și la livrare. Totul a fost super!",
      rating: 4,
      halfRating: 1,
      name: "George Matei",
      image:
        "https://driadashop.eu/image/cache/catalog/review1/46-1000x1333.jpg.webp",
    },
    {
      title: "Testo, Anavar, Anadrol și Arimidex",
      description:
        "Am dat comanda de Testo, Anavar, Anadrol și Arimidex, toate par originale.",
      rating: 5,
      halfRating: 0,
      name: "Sorin Iacob",
      image:
        "https://driadashop.eu/image/cache/catalog/@reviews/5-1170x1560.jpeg.webp",
    },
    {
      title: "Singurul din Romania cu MK original",
      description:
        "Am căutat o sursă legitimă de MK-677 foarte mult timp și am găsit aici în sfârșit. Ești numărul 1 pe ceea ce faci, bro.",
      rating: 5,
      halfRating: 0,
      name: "FlorinR",
      image:
        "https://driadashop.eu/image/cache/catalog/review1/45-1210x908.JPG.webp",
    },
  ];

  return (
    <div className="simple-slider">
      <h2 className="text-2">Ce spun clientii?</h2>
      <Slider {...settings}>
        {reviews.map((review, index) => (
          <Review
            key={index}
            title={review.title}
            description={review.description}
            rating={review.rating}
            name={review.name}
            halfRating={review.halfRating}
            image={review.image}
          />
        ))}
      </Slider>
    </div>
  );
}
